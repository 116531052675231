.regionen-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/banner.webp);

		&.region-10163 {
			background-image: url(RESOURCE/img/banner_dierhagen.webp);
		}

		&.region-10193 {
			background-image: url(RESOURCE/img/banner_ahrenshoop.webp);
		}

		&.region-10164 {
			background-image: url(RESOURCE/img/banner_Prerow.webp);
		}

		&.region-10165 {
			background-image: url(RESOURCE/img/banner_wustrow.webp);
		}

		&.region-10167 {
			background-image: url(RESOURCE/img/banner_zingst.webp);
		}

		&.region-10162 {
			background-image: url(RESOURCE/img/banner_born.webp);
		}

		&.region-10166 {
			background-image: url(RESOURCE/img/banner_wieck.webp);
		}

	}
}